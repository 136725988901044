import React, { useState, useEffect } from 'react'
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"
import primaryColor from "@material-ui/core/colors/indigo"
import axios from "axios"
import PortHardLimits from "../PortHardLimits"
import PortFormDialog from "../PortFormDialog"
import Alert from '@material-ui/lab/Alert';
import ProgressBar from "../ProgressBar"
import {useHistory} from "react-router-dom"


const TableHeaderCell = withStyles({
    root: {
        background: primaryColor[500],
        borderRadius: 8,
        border: 0,
        color: 'white',
        height: 45,

        boxShadow: '0 3px 5px 2px rgba(28, 10, 120, .3)',



    }
})(TableCell);
const PortManager = () => {
    document.body.style.backgroundColor = grey[300];

    const history = useHistory()

    const [portData, setPortData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [progress, setProgress] = useState(false)

    // const [updatePorts, setUpdatePorts] = useState([])



    // const handlePortUpdateRefresh = (updatePorts) => {
    //     setUpdatePorts(updatePorts)
    // }

    const [update, setUpdate] = useState("")
    const [showDelBannerType, setShowDelBannerType] = useState("")
    const [delStatus, setDelStatus] = useState(false)
    const [delStatusText, setDelStatusText] = useState("")

    const handleAddDelStatus = (showBanner, updateText = undefined) => {

        setShowDelBannerType(showBanner)
        setDelStatus(true)
        if (updateText) {
            setDelStatusText(updateText)
        } else {
            if (showBanner === "success") {
                setDelStatusText("Succesfully Deleted !")
            } else if (showBanner === "info") {
                setDelStatusText("Succesfully Added !")
            }
            else {
                setDelStatusText("Error Occured While Deleting !")
            }
        }


    }

    const handleUpdate = (val) => {
        setUpdate(`${Math.random()}_${val.toString()}`)
    }

    useEffect(() => {

        // if (update==="") return

        setProgress(true)
        document.title = "GMP - Please Wait..."
        let source = axios.CancelToken.source()
        const getData = async () => {
            
            // const headers = {
            //     withCredentials: true
            // }

            try {
                
                // const response = await axios.get("/slimstest")
                const response = await axios.get(process.env.REACT_APP_API_URL + "/slims/group", { cancelToken: source.token, withCredentials: true })
                setPortData(response.data)
                setProgress(false)
                setLoaded(true)
                document.title = "GMP - Port-Manager"
                setTimeout(() => {
                    setDelStatus(false)
                    setDelStatusText("")
        
                }, 5000);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.info("Last Request Was Cancelled")
                } 
                else {
                    if (error.response.status === 401) {
                        history.push("/")
                    }
                    // console.log(error)
                    setProgress(false)
                    
                }
            }

            
        }
        
        getData()

        
        
        return () => {
            // console.log("unmount")
            source.cancel()
            
        
            // console.clear()
        }
        
       
    }, [update, history])

    const ports = []

    for (let portsRange = 1; portsRange <= 64; portsRange++) {
        ports.push(portsRange.toString())

    }

    if(loaded){
        // setUpdate(false)
    return (
        <React.Fragment>
            {progress && <ProgressBar /> }
            {progress===false && delStatus && <Alert severity={showDelBannerType} >{delStatusText}</Alert>}
            <PortFormDialog handleUpdate={handleUpdate} handleAddDelStatus={handleAddDelStatus} />
            <TableContainer component={Paper} elevation={20} style={{ margin: "20px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Apply / Delete ?</TableHeaderCell>
                            <TableHeaderCell>Camapign</TableHeaderCell>
                            <TableHeaderCell>SimBox Port</TableHeaderCell>
                            <TableHeaderCell>ATT HARDLIMIT</TableHeaderCell>
                            <TableHeaderCell>VZW HARDLIMIT</TableHeaderCell>
                            <TableHeaderCell>TMO HARDLIMIT</TableHeaderCell>
                            <TableHeaderCell>Sprint HARDLIMIT</TableHeaderCell>
                            <TableHeaderCell>OTHER HARDLIMIT</TableHeaderCell>
                            <TableHeaderCell>Sim Port's Selection</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {
                            portData.map((val, ind) => {
                                return (

                                    <TableRow key={ind} hover>


                                        <PortHardLimits ports={ports} selectedPorts={val.AllowedPorts}
                                            campaign={val.Campaign} allowedBox={val.AllowedBox}
                                            hardLimits={val.HardLimit}
                                            // handlePortUpdateRefresh={handlePortUpdateRefresh}
                                            handleUpdate={handleUpdate}
                                            handleAddDelStatus={handleAddDelStatus}
                                        />
                                    </TableRow>


                                )
                            })
                        }

                    </TableBody>
                </Table>
            </TableContainer>

        </React.Fragment>
    )
}else{
    return (
        <React.Fragment>
            <ProgressBar />
            <h1>Please Wait...</h1>
        </React.Fragment>
    );
}
}

export default PortManager
