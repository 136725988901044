import React, { useState, useEffect } from 'react'
import { Button, TextField, TableCell } from "@material-ui/core"
import PortMenus from "./PortMenus"
import { useConfirm } from 'material-ui-confirm';
import axios from "axios"
import {useHistory} from "react-router-dom"
import DeleteIcon from '@material-ui/icons/CancelPresentationTwoTone';
import AddIcon from '@material-ui/icons/AddBoxTwoTone';

const PortHardLimits = (props) => {
    const history = useHistory()

    const confirm = useConfirm();
    const [applyButton, setApplyButton] = useState(true)
    const [slims, setSlims] = useState("")
    const [selectedPorts, setSelectedPorts] = useState(props.selectedPorts)
    
    

    const applyButtonFunc = (val) => {
        setApplyButton(val)
        setSlims("")
    }

    const finalApply = () => {
        setSlims({
            Campaign : props.campaign,
            AllowedBox : props.allowedBox,
            AttCountsLimit : attCountsLimit,
            AttDuration : attDuration,
            VzwCountsLimit : vzwCountsLimit,
            VzwDuration : vzwDuration,
            TmoCountsLimit : tmoCountsLimit,
            TmoDuration : tmoDuration,
            SprintCountsLimit : sprintCountsLimit,
            SprintDuration : sprintDuration,
            AllotherCountsLimit : allotherCountsLimit,
            AllotherDuration : allotherDuration
        })

        setApplyButton(true)
        // props.handleUpdate(true)
    }
    
    useEffect(() => {
        setSlims("")
    }, [slims])


    const [attCountsLimit, setAttCountsLimit] = useState(props.hardLimits.ATT.Limit)
    const [attDuration, setAttDuration] = useState(props.hardLimits.ATT.Duration)

    const [vzwCountsLimit, setVzwCountsLimit] = useState(props.hardLimits.VZW.Limit)
    const [vzwDuration, setVzwDuration] = useState(props.hardLimits.VZW.Duration)

    const [tmoCountsLimit, setTmoCountsLimit] = useState(props.hardLimits.TMO.Limit)
    const [tmoDuration, setTmoDuration] = useState(props.hardLimits.TMO.Duration)

    const [sprintCountsLimit, setSprintCountsLimit] = useState(props.hardLimits.SPRINT.Limit)
    const [sprintDuration, setSprintDuration] = useState(props.hardLimits.SPRINT.Duration)

    const [allotherCountsLimit, setAllotherCountsLimit] = useState(props.hardLimits.ALLOTHER.Limit)
    const [allotherDuration, setAllotherDuration] = useState(props.hardLimits.ALLOTHER.Duration)


    const {hardLimits} = props
    const selPorts = props.selectedPorts

    useEffect(() => {

        setAttCountsLimit(hardLimits.ATT.Limit)
        setAttDuration(hardLimits.ATT.Duration)
        setVzwCountsLimit(hardLimits.VZW.Limit)
        setVzwDuration(hardLimits.VZW.Duration)
        setTmoCountsLimit(hardLimits.TMO.Limit)
        setTmoDuration(hardLimits.TMO.Duration)
        setSprintCountsLimit(hardLimits.SPRINT.Limit)
        setSprintDuration(hardLimits.SPRINT.Duration)
        setAllotherCountsLimit(hardLimits.ALLOTHER.Limit)
        setAllotherDuration(hardLimits.ALLOTHER.Duration)

        setSelectedPorts(selPorts)
    }, [hardLimits, selPorts])




    const updateStatesCount = (event, stateCounts, StateDuration) => {

        // console.log(event.target.value)

        stateCounts(() => {
            if (isNaN(parseInt(event.target.value))) {
                StateDuration(360000)
                return 10000000
            } else {
                return parseInt(event.target.value)
            }
        })

        setApplyButton(false)

    }


    const updateCountsLimitByDuration = (event, durationState, stateCounts) => {


        durationState(() => {
            if (parseInt(event.target.value) === 360000) {
                stateCounts(10000000)
            }
            return parseInt(event.target.value)
        })

        setApplyButton(false)
    }

    const selectText = (event) => {
        // event.target.select()
        // console.log(event.target)
        if(event.target.value){
            event.target.select()
        }
    }

const deleteItem = async(object) => {
    confirm({ description: 'This action is permanent!' })
        .then( async() => {
            // const headers = {
            //     withCredentials: true
            // }

            try {
                // console.log(object)

                await axios.delete(process.env.REACT_APP_API_URL + `/slims`, { data:object, withCredentials: true })
                // console.log(getDataResult)
    
                // props.delStatus("yes", Math.random())
                props.handleUpdate(true)
                props.handleAddDelStatus("success")
                
                
            } catch (error) {
                
                    if (error.response.status === 401) {
                        return(history.push("/"))
                    }
                    // props.delStatus("error")
                    // console.log(error)
                    props.handleUpdate(true)
                    props.handleAddDelStatus("error")
            }


        }).catch(() => console.log("Delete Cancelled"))
}



    return (
        <React.Fragment>

            <TableCell>
                <Button size="small" onClick={() => deleteItem(
                    {
                        "SLIMS.Details.Campaign" : props.campaign,
                        "SLIMS.Details.AllowedBox":props.allowedBox

                    }
                )} color="secondary" variant="contained" startIcon={<DeleteIcon />} >Delete</Button>
                &nbsp;
                <Button size="small" startIcon={<AddIcon />} onClick={finalApply} disabled={applyButton} color="primary" variant="contained" >Apply</Button>
                
                
                
            </TableCell>
            <TableCell> {props.campaign} </TableCell>
            <TableCell> {props.allowedBox} </TableCell>


            <TableCell>
                <TextField
                    value={attCountsLimit}

                    onChange={(event) => updateStatesCount(event, setAttCountsLimit, setAttDuration)}
                    onClick={selectText}

                    style={{ width: "104px" }} placeholder="ATT Limit" label="Limit" variant="standard" />
                <br />
                <br />
                <TextField

                    onChange={(event) => updateCountsLimitByDuration(event, setAttDuration, setAttCountsLimit)}
                    
                    style={{ width: "104px" }} select placeholder="ATT Duration"
                    label="Duration"
                    variant="standard"
                    SelectProps={{
                        native: true,
                    }}
                    value={attDuration}
                >
                    <option value={100 * 60 * 60}>None</option>
                    <option value={60 * 30}>30 Minutes</option>
                    <option value={60 * 60}>1 Hour</option>
                    <option value={60 * 90}>1½ Hour</option>
                    <option value={60 * 120}>2 Hour</option>
                </TextField>
            </TableCell>



            <TableCell>
                <TextField
                    value={vzwCountsLimit}
                    onChange={(event) => updateStatesCount(event, setVzwCountsLimit, setVzwDuration)}
                    onClick={selectText}
                    style={{ width: "104px" }} placeholder="VZW Limit" label="Limit" variant="standard" />
                <br />
                <br />
                <TextField
                    onChange={(event) => updateCountsLimitByDuration(event, setVzwDuration, setVzwCountsLimit)}
                    style={{ width: "104px" }} select placeholder="VZW Duration"
                    label="Duration"
                    variant="standard"
                    SelectProps={{
                        native: true,
                    }}
                    value={vzwDuration}
                >
                    <option value={100 * 60 * 60}>None</option>
                    <option value={60 * 30}>30 Minutes</option>
                    <option value={60 * 60}>1 Hour</option>
                    <option value={60 * 90}>1½ Hour</option>
                    <option value={60 * 120}>2 Hour</option>
                </TextField>
            </TableCell>


            <TableCell>
                <TextField
                    value={tmoCountsLimit}
                    onChange={(event) => updateStatesCount(event, setTmoCountsLimit, setTmoDuration)}
                    onClick={selectText}
                    style={{ width: "104px" }} placeholder="TMO Limit" label="Limit" variant="standard" />
                <br />
                <br />
                <TextField
                    onChange={(event) => updateCountsLimitByDuration(event, setTmoDuration, setTmoCountsLimit)}
                    style={{ width: "104px" }} select placeholder="TMO Duration"
                    label="Duration"
                    variant="standard"
                    SelectProps={{
                        native: true,
                    }}
                    value={tmoDuration}
                >
                    <option value={100 * 60 * 60}>None</option>
                    <option value={60 * 30}>30 Minutes</option>
                    <option value={60 * 60}>1 Hour</option>
                    <option value={60 * 90}>1½ Hour</option>
                    <option value={60 * 120}>2 Hour</option>
                </TextField>
            </TableCell>



            <TableCell>
                <TextField
                    value={sprintCountsLimit}
                    onChange={(event) => updateStatesCount(event, setSprintCountsLimit, setSprintDuration)}
                    onClick={selectText}
                    style={{ width: "104px" }} placeholder="SPRINT Limit" label="Limit" variant="standard" />
                <br />
                <br />
                <TextField
                    onChange={(event) => updateCountsLimitByDuration(event, setSprintDuration, setSprintCountsLimit)}
                    style={{ width: "104px" }} select placeholder="SPRINT Duration"
                    label="Duration"
                    variant="standard"
                    SelectProps={{
                        native: true,
                    }}
                    value={sprintDuration}
                >
                    <option value={100 * 60 * 60}>None</option>
                    <option value={60 * 30}>30 Minutes</option>
                    <option value={60 * 60}>1 Hour</option>
                    <option value={60 * 90}>1½ Hour</option>
                    <option value={60 * 120}>2 Hour</option>
                </TextField>
            </TableCell>


            <TableCell>
                <TextField
                    value={allotherCountsLimit}
                    onChange={(event) => updateStatesCount(event, setAllotherCountsLimit, setAllotherDuration)}
                    onClick={selectText}
                    style={{ width: "104px" }} placeholder="SPRINT Limit" label="Limit" variant="standard" />
                <br />
                <br />
                <TextField
                    onChange={(event) => updateCountsLimitByDuration(event, setAllotherDuration, setAllotherCountsLimit)}
                    style={{ width: "104px" }} select placeholder="SPRINT Duration"
                    label="Duration"
                    variant="standard"
                    SelectProps={{
                        native: true,
                    }}
                    value={allotherDuration}
                >
                    <option value={100 * 60 * 60}>None</option>
                    <option value={60 * 30}>30 Minutes</option>
                    <option value={60 * 60}>1 Hour</option>
                    <option value={60 * 90}>1½ Hour</option>
                    <option value={60 * 120}>2 Hour</option>
                </TextField>
            </TableCell>




            <PortMenus ports={props.ports}
                selectedPorts={selectedPorts}
                campaign={props.campaign}
                allowedBox={props.allowedBox}
                // handlePortUpdateRefresh={props.handlePortUpdateRefresh}
                applyButtonFunc={applyButtonFunc}
                finalApply={finalApply}
                slims={slims}
                handleUpdate={props.handleUpdate}
                handleAddDelStatus={props.handleAddDelStatus}
                
            />

        </React.Fragment>
    )
}

export default PortHardLimits
