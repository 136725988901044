import React, { useState } from 'react'
import {Container, Typography, Button, Paper, Box, TextField} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"
import Alert from '@material-ui/lab/Alert';
import axios from "axios"
import { useHistory } from 'react-router-dom';
import SignInButtonIcon from '@material-ui/icons/LaunchTwoTone';
import SignInIcon from '@material-ui/icons/PeopleOutlineTwoTone';



const useStyle = makeStyles(theme => ({
    root: {
        width: "100vw",
        height : "80vh",
        backgroundColor : theme.palette.grey[300],
        paddingTop : theme.spacing(5)
    }
}))


const Login = () => {
    const classes = useStyle()
    document.body.style.backgroundColor = grey[300];


    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [submitButtonText, setSubmitButtonText] = useState("Sign In")
    const [invalidLogin, setInvalidLogin] = useState("")

    const history = useHistory()

    const postLogin = async(e) => {
        e.preventDefault()
        setSubmitButtonText("Please Wait...")

        const data = {
            email : email,
            password : password

        }
        
        console.log(data)
        const headers =  { 
            // 'Content-Type': 'application/json',
            withCredentials: true
          }

        try {
            const loginCheck = await axios.post(process.env.REACT_APP_API_URL + "/login", data, headers)
            console.log(loginCheck)
            if(loginCheck.status === 200){
                localStorage.setItem("user", loginCheck.data.id);
                history.push("/about")
            }
        } catch (error) {
            console.log(error)
            setInvalidLogin(<Alert severity="error">Invalid Email/Password, Try Again ?</Alert>)
            setSubmitButtonText("Sign In")
        }
        

    }


    return (
        
        <Container disableGutters className={classes.root}>
            <Paper component={Box} width="35%" mx="auto" p={4}>
                <Typography variant="h5"><SignInIcon color="primary"/> Sign in</Typography>
                {invalidLogin}
                {/* <Divider style={{backgroundColor:"#607d8b"}} variant="inset" /> */}
               <Box component="form" mt={2} onSubmit={postLogin}>
               <TextField 
               type="email"
                    fullWidth
                    variant="outlined"
                    placeholder="Please Enter Email Address"
                    label="Email Address"
                    color="secondary"
                    margin="normal"
                    required
                    onChange={(e) => {setEmail(e.target.value); setInvalidLogin("")}}
                    value={email}
                    ></TextField>


                    <TextField 
                    type="password"
                    fullWidth
                    variant="outlined"
                    placeholder="Please EnterYour Password"
                    label="Password"
                    color="secondary"
                    margin="normal"
                    required
                    onChange={(e) => {setPassword(e.target.value); setInvalidLogin("")}}
                    value={password}
                    ></TextField>
                    <br/><br/>
                    <Button startIcon={<SignInButtonIcon/>} type="submit" fullWidth color="primary" variant="contained">{submitButtonText}</Button>
               </Box>
            </Paper>
        </Container>
    )
}

export default Login
