import React, { useState, useEffect } from 'react'
import {Container, Typography, Box} from "@material-ui/core"
import grey from "@material-ui/core/colors/grey"
import axios from "axios"
import { useHistory } from 'react-router-dom';


const About = () => {
    document.body.style.backgroundColor = grey[300];
    const history = useHistory()
    
    const [loaded, setloaded] = useState(false)
    
    const [data, setData] = useState({
        id : "",
        role : "",
        firstName : "",
        lastName : "",
        email : "",
        date : ""
    })
    

    useEffect(() => {
        const getData = async() => {
        
            try {
                const user = localStorage.getItem("user");
                const getDataResult = await axios.get(process.env.REACT_APP_API_URL + `/users/${user}`, { withCredentials: true })
                setData({id : getDataResult.data._id, role : getDataResult.data.role, 
                    firstName : getDataResult.data.firstName,
                    lastName : getDataResult.data.lastName,
                    email : getDataResult.data.email,
                    date : getDataResult.data.date})
                setloaded(true)
                
                
            } catch (error) {
                history.push("/")
            }
        }
        getData()
    },[history]) 

    
    if(loaded){
        console.log(data)
        return (
            <Container component={Box} disableGutters mx="auto" p={4}>
            <Typography component={Box} mx="auto" p={15}  align="center" color="textSecondary" variant="h4">Welcome {data.firstName}, To GOIP PlatForm Manager</Typography>
        </Container>
        )
    }
    else{
        return(
            <h1>Please Wait....</h1>
        )
    }
    
}

export default About
