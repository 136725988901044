import { AppBar, IconButton, Toolbar, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import SideBar from "./SideBar/SideBar"
import MenuIcon from "@material-ui/icons/Menu"
import { useLocation } from "react-router-dom"
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import teal from "@material-ui/core/colors/cyan";



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    },
    paper: {
        background: teal[500]
      }
  }));

const NavBar = () => {
    const Menus = [
        { text: "Messages", to: "/messages" },
    { text: "Campaigns", to: "/campaigns" },
    { text: "Carrier Mapping", to: "/carrier-mapping" },
    { text: "Port Manager", to: "/port-manager" },
    { text: "Gateway Settings", to: "/gateway-settings" },
    { text: "Slims Domains", to: "/slims-domains"}
]
    const [openDrawer, setOpenDrawer] = useState(false)
    const location = useLocation()


    const classes = useStyles()

    const checkPath = () => {
        let path = location.pathname.split("/")

        if(path[1]){
            return location.pathname.replace("/", " - ")
        }else{
            return " - Home"
        }

    }
    return (
        <React.Fragment>
            
            <AppBar  style={{backgroundColor: teal[500]}} className={classes.root} >
                <Toolbar >
                {location.pathname !== "/" &&   <IconButton color="inherit" onClick={() => setOpenDrawer(true)}>
                        <MenuIcon />
                    </IconButton>}
                    <Typography variant="h6" className={classes.title} style={{ textTransform: "capitalize" }} >
                    GOIP - Manager Portal  {checkPath()}
                        </Typography>
                
                
                        <IconButton className={classes.menuButton} aria-label="show 11 new notifications" color="inherit">
                            <Badge badgeContent={11} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    
                    {/* <Button color="inherit">Login</Button> */}
                    </Toolbar>
                    
            </AppBar>
            <Toolbar />
            <SideBar Menus={Menus} open={openDrawer} close={() => setOpenDrawer(false)} color={classes.paper} />
        </React.Fragment>

    )
}

export default NavBar
