import React, { useEffect, useState } from 'react'
import { TextField, Paper, Container, Button, Divider } from "@material-ui/core"
import grey from "@material-ui/core/colors/grey"
import ChipInput from 'material-ui-chip-input'
import axios from "axios"
import {useHistory} from "react-router-dom"
import ProgressBar from "../ProgressBar"
import Alert from '@material-ui/lab/Alert';
import isPort from "validator/lib/isPort";
import AddIcon from '@material-ui/icons/AddBoxTwoTone';
import { useConfirm } from 'material-ui-confirm';

const Gateway = () => {
    const confirm = useConfirm()
    const history = useHistory()
    const [progress, setProgress] = useState(false)
    const [loaded, setloaded] = useState(false)
    const [update, setUpdate] = useState("")
    const [showDelBannerType, setShowDelBannerType] = useState("")
    const [delStatus, setDelStatus] = useState(false)
    const [delStatusText, setDelStatusText] = useState("")

    const [data, setData] = useState({
        GOIP_USER : "",
        GOIP_PASSWORD : "",
        MO_SECRET_KEY: "",
        SECRET_KEY: "",
        SR_WAITCOUNT: "",
        SR_WAITTIME: "",
        MONGO_URL: "",
        CARRIER_LOOKUP_URL: "",
        JWT_SECRET_MASTER: "",
        SIM_BOXES : []

    })


    const [buttonStatus, setButtonStatus] = useState(true)
    

    useEffect(() => {
        
        document.title = "GMP - Please Wait..."
        const fetchData = async() => {
            
            const headers = {
                withCredentials: true
            }

            try {
                setProgress(true)

                const gatwayDatas = await axios.get(process.env.REACT_APP_API_URL + "/gateway", headers)
                // console.log(gatwayDatas.data)
                const { GOIP_USER,GOIP_PASSWORD,MO_SECRET_KEY,SECRET_KEY,SR_WAITCOUNT,SR_WAITTIME,
                    MONGO_URL,CARRIER_LOOKUP_URL,JWT_SECRET_MASTER, SIM_BOXES} = gatwayDatas.data
                    const SIM_BOXES_ARRAY = JSON.parse(SIM_BOXES)
                setData((prev) => {
                    return {
                        ...prev,
                        GOIP_USER,GOIP_PASSWORD,MO_SECRET_KEY,SECRET_KEY,SR_WAITCOUNT,SR_WAITTIME,
                    MONGO_URL,CARRIER_LOOKUP_URL,JWT_SECRET_MASTER, SIM_BOXES : SIM_BOXES_ARRAY
                    }
                })
                setProgress(false)
                setloaded(true)
                document.title = "GMP - Gateway-Settings"
            } catch (error) {
                console.log(error)
                if(error.response.status === 401){
                    return(history.push("/"))
                    
                }

                handleAddDelStatus("error", "Error Occured While Fetching Details !")
            }

        }

        fetchData()

    }, [history, update])

    

    const handleAddDelStatus = (showBanner, updateText = undefined) => {

        setShowDelBannerType(showBanner)
        setDelStatus(true)
        if (updateText) {
            setDelStatusText(updateText)
        } else {
            if (showBanner === "success") {
                setDelStatusText("Succesfully Deleted !")
            } else if (showBanner === "info") {
                setDelStatusText("Succesfully Added !")
            }
            else {
                setDelStatusText("Error Occured While Deleting !")
            }
        }

        setTimeout(() => {
            setDelStatus(false)
            setDelStatusText("")

        }, 5000);

    }

    const handleChanges = (event) => {
        const {name, value} = event.target

        setData({...data, [name] : value})

        setButtonStatus(false)
    }

    // const [simBoxes, setSimBoxes] = useState([])

    const handleAddChip = (chip) => {

        console.log(chip)
        setData({...data, SIM_BOXES : [...data.SIM_BOXES, chip]})
        setButtonStatus(false)
    }

    
    
    const handleUpdate = (val) => {
        setUpdate(`${Math.random()}_${val.toString()}`)
    }

    const handleDeleteChip = (chip, index) => {

        // console.log(chip, index)
        console.log(index)

        setData({...data, SIM_BOXES :
            data.SIM_BOXES.filter((val, ind) => {
                // console.log(ind)
                return ind !== index
            })
        })

        setButtonStatus(false)
    }

    const sendUpdate = async() => {

        confirm({ description: 'About To Change Gateway Setting. Action Is Permanent, Proceed ?' })
        .then(async() => {

        const headers = {
            withCredentials: true
        }

        try {
            await axios.put(process.env.REACT_APP_API_URL + "/gateway", data, headers)
            handleAddDelStatus("success", "Successfully Updated !")
            handleUpdate(true)
            setButtonStatus(true)
        } catch (error) {
            if(error.response.status === 401){
                history.push("/")
            }

            handleAddDelStatus("error", "Error Occured While Updating !")
        }
    }).catch(() => { console.log("Update Cancelled"); setButtonStatus(true); handleUpdate(true) })
    }

    document.body.style.backgroundColor = grey[300];

    if (loaded) {

    return (
        <React.Fragment>
            { progress && <ProgressBar />}
            {progress===false && delStatus && <Alert severity={showDelBannerType} >{delStatusText}</Alert>}
            <Container style={{ width: "800px", marginTop: "15px", paddingTop: "20px", paddingBottom: "20px" }} component={Paper} elevation={10}  >




                <center>
                    <TextField style={{ paddingRight: "40px" }} size="small" variant="filled"
                        label="GOIP Username"
                        value={data.GOIP_USER}
                        name="GOIP_USER"
                        onChange={handleChanges}
                    />

                    <TextField style={{ paddingRight: "40px" }} type="text" size="small" variant="filled"
                        label="GOIP Password"
                        value={data.GOIP_PASSWORD}
                        name="GOIP_PASSWORD"
                        onChange={handleChanges}
                    />
                    <TextField type="text" size="small" variant="filled"
                        label="MO SECRET KEY"
                        value={data.MO_SECRET_KEY}
                        name="MO_SECRET_KEY"
                        onChange={handleChanges}
                    />
                    <br />
                    <br />

                    <TextField style={{ paddingRight: "40px" }} type="text" size="small" variant="filled"
                        label="MT SECRET KEY"
                        value={data.SECRET_KEY}
                        name="SECRET_KEY"
                        onChange={handleChanges}
                    />



                    <TextField style={{ paddingRight: "40px" }} type="number" size="small" variant="filled"
                        label="SR WAIT COUNT"
                        value={data.SR_WAITCOUNT}
                        name="SR_WAITCOUNT"
                        onChange={handleChanges}
                    />

                    <TextField type="number" size="small" variant="filled"
                        label="SR WAIT TIME"
                        value={data.SR_WAITTIME}
                        name="SR_WAITTIME"
                        onChange={handleChanges}
                    />
                </center>

                <br />
                <Divider />
                <br />
                <TextField fullWidth type="url" size="small" variant="outlined"
                    label="MONGO DB URL"
                    value={data.MONGO_URL}
                    name="MONGO_URL"
                    onChange={handleChanges}
                />

                <br />
                <br />
                <TextField fullWidth type="url" size="small" variant="outlined"
                    label="CARRIER LOOKUP URL"
                    value={data.CARRIER_LOOKUP_URL}
                    name="CARRIER_LOOKUP_URL"
                    onChange={handleChanges}
                />

                <br />
                <br />
                <TextField disabled fullWidth type="text" size="small" variant="outlined"
                    value={data.JWT_SECRET_MASTER}
                    label="JWT SECRET MASTER"
                    name="JWT_SECRET_MASTER"
                    onChange={handleChanges}
                />


                <br />
                <br />
                <Divider />
                <ChipInput

                    variant="outlined"
                    name="SIM_BOXES"
                    type="number"
                    label="Sim Boxes"
                    fullWidth
                    value={data.SIM_BOXES}
                    onAdd={(chip) => handleAddChip(chip)}
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    onBeforeAdd={(chip) => {
                        return isPort(chip)
                    }}
                />


                <br />

                <br />
                <Divider />
                <Button startIcon={<AddIcon />} onClick={sendUpdate} disabled={buttonStatus} color="primary" variant="contained" fullWidth>Update Gateway Settings</Button>




            </Container>
        </React.Fragment>
    )
    }else{
        return (
            <React.Fragment>
                <ProgressBar />
                <h1>Please Wait...</h1>
            </React.Fragment>
        );
    }
}

export default Gateway
