import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Collapse,
  Backdrop,
  CircularProgress,
  makeStyles,
  Divider,
  Grid,
  ButtonGroup,
  TextField
} from '@material-ui/core';
import { CloudDownload, Add, Check, CloudUpload, DeleteForever } from '@material-ui/icons';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import grey from "@material-ui/core/colors/grey";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  successButton: {
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const SlimsDomains = () => {
  document.body.style.backgroundColor = grey[300];
  const history = useHistory()
  const classes = useStyles();
  const [loaded, setloaded] = useState(false)
  const [serverResponse, setServerResponse] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [actualFile, setActualFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [showTextarea, setShowTextarea] = useState(false);
  const [showDuplicateDomainsTable, setShowDuplicateDomainsTable] = useState(null);
  const [setControllerParams, setSetControllerParams] = useState({
    totalTmoMessages: "",
    messagesPerHour: "",
    maxPerPortMessageLimit: "",
    perDomainMessageLimit: "",
    totalDomainsPerSet: "",
    minimumSetThreshold: "",
    setsActiveAtOneTime: ""
  });

  const [setCreationStatus, setSetCreationStatus] = useState(null);
  const [duplicateDomainsStatus, setDuplicateDomainsStatus] = useState(false); // ['domain1', 'domain2'
  const tableRef = useRef(null);
  const [campaignList, setCampaignList] = useState([])

  const handleCreateSet = () => {
    // Simulate server response for demonstration purposes
    setShowBackdrop(true);

    const createSet = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/slims/domains/createSets', setControllerParams, {
          withCredentials: true,
        });
        console.log(response);
        if (response.status === 200) {
          // setUploadStatus('File Uploaded');
        }
        setSetCreationStatus(true);
        setServerResponse(JSON.stringify(response.data, null, 2));
        setShowBackdrop(false);
        
      } catch (error) {
        console.error(error);
        setSetCreationStatus(false);
        setServerResponse(JSON.stringify(error.response.data, null, 2));
        setShowBackdrop(false);
      }
    };

    createSet();


      // const response = 'Server response here...'; // Replace this with the actual server response
      // setServerResponse(JSON.stringify(setControllerParams, null, 2));
      // setShowBackdrop(false);
      setShowTextarea(true); // Show the textarea when Create Set is clicked
  };

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    if (file) {
        setActualFile(file);
        if(file.name !== selectedFileName){
            setUploadStatus('')
        }


      // File is selected
      setFileSelected(true);
      setSelectedFileName(file.name);
      setShowTextarea(false); // Hide the textarea when a file is selected
    } else {
      // File selection is cancelled
      setFileSelected(false);
      setSelectedFileName('');
    }
  };

  const handleTickClick = () => {
    // Start file upload process here
    // Simulating a delay for file processing
    setShowBackdrop(true);

    // check actualFile is csv file or not
    if(actualFile.type !== 'text/csv'){
        alert('Error: Only CSV File Allowed.');
        setShowBackdrop(false);
        return;
    }


    readFileContent(actualFile).then((content) => {
      // console.log(content);
      // convert csv to json
      const csv = content;
      const lines = csv.split('\n');
      const result = [];
      const headers = lines[0].split(',');
      for (let i = 1; i < lines.length; i++) {

        if(lines[i].trim() === ''){
            continue;
        }

        const obj = {};
        const currentline = lines[i].split(',');
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentline[j].trim();
        }
        result.push(obj);
      }

      // check if result in right format
      if(result.length === 0){
        alert('Error: File is not in correct format.');
        setShowBackdrop(false);
        return;
      }

      // check if result has domain field
      if(!result[0].hasOwnProperty('domain')){
        alert('Error: File is not in correct format.');
        setShowBackdrop(false);
        return;
      }

      // check if result has other fields than domain
      if(Object.keys(result[0]).length > 1){
        alert('Error: File is not in correct format.');
        setShowBackdrop(false);
        return;
      }


      // console.log(JSON.stringify(result)); // JSON
      setFileContent(result);
    });
    // setTimeout(() => {
    //   console.log('File upload started.');
    //   setShowBackdrop(false);
    //   setUploadStatus('File Uploaded');
    // }, 1500); // Simulated delay
  };

 
  const downloadUnusedCSV = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/slims/domains/reserve', {
        params: {format: 'csv' },
        responseType: 'blob',
        withCredentials: true,
      });

      if (response.headers['content-type'] === 'text/csv; charset=utf-8') {
        const fileName = `slims_unUsedDomains_${moment().format('YYYYMMDD_HHmmss')}.csv`;

        // check if response.data] is null or empty
        if (!response.data || response.data.length === 0) {
            
            alert('No Unused Domains Found.');
            return;
        }
        

        // Use file-saver to trigger the download
        saveAs(new Blob([response.data], { type: 'text/csv' }), fileName);
      }
    } catch (error) {
      console.error(error);
      
      alert('No Unused Domains Found.');
    }
  };

  // delete unused domains
  const deleteUnusedDomains = async () => {
    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + '/slims/domains/reserve', {
        withCredentials: true
      });

      // check status code
      if (response.status === 200) {
        alert('Unused domains deleted successfully.');
      }
    } catch (error) {
      console.error(error);
      alert('There was an error deleting unused domains.');
    }
  };

  // read content of file and return as string
  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  useEffect(() => {
    if (fileSelected && fileContent) {
      console.log('File upload started.');
      
      // post json to server
      const postData = async () => {
        try {
          const response = await axios.post(process.env.REACT_APP_API_URL + '/slims/domains/reserve', fileContent, {
            withCredentials: true,
          });
          console.log(response);
          if (response.status === 200) {
            // setUploadStatus('File Uploaded');
            setShowDuplicateDomainsTable(null);
            setShowTextarea(false);


            setShowBackdrop(false);
            setUploadStatus('File Uploaded');
          }

        } catch (error) {
          console.log(error.response.data)
          // check if error is 409
          if(error.response.status === 409){

            // const error.response.data = {
            //   "message": "Bad Request",
            //   "error": "Duplicate Domains Found in Slims Reserve Domains Collection",
            //   "domains": [
            //     "aylrpl.us",
            //     "bcvcc.us",
            //     "bdeaem.us",
            //     "bdflc.us",
            //     "bejiz.us",
            //     "bhatxo.us",
            //     "bicvuu.us",
            //     "bjbdm.us",
            //     "bjnwmh.us",
            //     "bjrrmy.us",
            //     "blabu.us",
            //     "bmpdpl.us",
            //     "bnbjk.us",
            //     "bpibpv.us",
            //     "bqrqup.us",
            //     "brttfg.us",
            //     "bvzmeu.us",
            //     "caddgf.us",
            //     "cbhvgg.us",
            //     "cfkab.us",
            //     "cfsxsd.us",
            //     "cknztm.us",
            //     "cnqtsf.us",
            //     "cnsxb.us",
            //     "coslh.us",
            //     "cpelvr.us",
            //     "cpsbhj.us",
            //     "cqdogd.us",
            //     "cuwest.us",
            //     "cwxebt.us",
            //     "cwzdmx.us",
            //     "dblfez.us",
            //     "ddfxlp.us",
            //     "ditnmd.us",
            //     "dkftn.us",
            //     "dkxmt.us",
            //     "dobgxo.us",
            //     "dpgtyq.us",
            //     "dviyjy.us",
            //     "dvzupj.us",
            //     "dwqebk.us",
            //     "dyuqb.us",
            //     "ecbdo.us",
            //     "edqhem.us",
            //     "eehulf.us",
            //     "efpyej.eu",
            //     "ehdu.eu",
            //     "ehkdb.us",
            //     "ehzioz.eu",
            //     "ekkg.eu",
            //     "elnlfg.us",
            //     "epqbd.us",
            //     "eqxisl.eu",
            //     "esydh.us",
            //     "etsdiz.us",
            //     "etxqu.us",
            //     "eulmry.us",
            //     "eviec.us",
            //     "exgpz.us",
            //     "exjy.eu",
            //     "eynxpd.us",
            //     "eyqu.eu",
            //     "ezfmo.us",
            //     "fdre.eu",
            //     "ferovd.us",
            //     "fewsu.us",
            //     "feznzl.us",
            //     "fijlzf.eu",
            //     "fkemk.us",
            //     "fkesjy.eu",
            //     "fkykq.us",
            //     "fliw.eu",
            //     "fmvkpf.us",
            //     "fnkj.eu",
            //     "fnlgak.eu",
            //     "fnuw.eu",
            //     "fozah.us",
            //     "fpevmv.us",
            //     "fpfl.eu",
            //     "fpgruw.us",
            //     "ftcxqc.eu",
            //     "ftfior.eu",
            //     "ftskvk.us",
            //     "ftvnn.us",
            //     "fvzn.eu",
            //     "fxcr.eu",
            //     "fxhtel.us",
            //     "gbeni.us",
            //     "gbgrto.eu",
            //     "gbhp.eu",
            //     "gbnvi.us",
            //     "gcug.eu",
            //     "gechih.us",
            //     "geojoj.us",
            //     "gesr.eu",
            //     "gevk.eu",
            //     "gfmcqo.us",
            //     "ggui.eu",
            //     "ghlwqm.eu",
            //     "ghymdf.eu",
            //     "gixtgv.us",
            //     "gjhcx.us",
            //     "gjuzyg.us",
            //     "gkrxol.us",
            //     "gkzlbd.us",
            //     "glblbb.eu",
            //     "glxrig.us",
            //     "gndc.eu",
            //     "gptifm.eu",
            //     "gqpc.eu",
            //     "gsrrle.us",
            //     "gsvp.eu",
            //     "gtiloj.us",
            //     "gtlj.eu",
            //     "gtsjgp.us",
            //     "gusvzq.us",
            //     "gxknmm.us",
            //     "gymp.eu",
            //     "gypq.eu",
            //     "gyqdvy.us",
            //     "gyzuem.us",
            //     "gzeg.eu",
            //     "gzgf.eu",
            //     "hbtmbd.us",
            //     "hbzkj.us",
            //     "hdob.eu",
            //     "hdrne.us",
            //     "hduu.eu",
            //     "hduz.eu",
            //     "hebxjk.us",
            //     "hiebrn.eu",
            //     "hjrz.eu",
            //     "hkqq.eu",
            //     "hkzrf.us",
            //     "hncpte.eu",
            //     "hncqio.us",
            //     "hnmk.eu",
            //     "hodmni.us",
            //     "huaa.eu",
            //     "huyjem.us",
            //     "hvbemq.eu",
            //     "hxhl.eu",
            //     "hxje.eu",
            //     "hzbc.eu",
            //     "hzmiuh.eu",
            //     "iafyui.us",
            //     "iaqt.eu",
            //     "ibfonq.us",
            //     "icpjjb.us",
            //     "icrpv.us",
            //     "iekkn.us",
            //     "iflxz.us",
            //     "ifqf.eu",
            //     "igymfu.us",
            //     "igzc.eu",
            //     "ijiv.eu",
            //     "ikgz.eu",
            //     "ikqcr.us",
            //     "ilkrzu.us",
            //     "imcpy.us",
            //     "imtwak.eu",
            //     "indual.us",
            //     "iofqdr.eu",
            //     "iourrd.us",
            //     "iqengz.eu",
            //     "iqwfcn.eu",
            //     "irvl.eu",
            //     "isspzs.us",
            //     "ithjl.us",
            //     "itwv.eu",
            //     "iudkt.us",
            //     "iuug.eu",
            //     "ivzk.eu",
            //     "iwune.us",
            //     "izfba.us",
            //     "jaaoc.us",
            //     "jasyxz.eu",
            //     "jcbn.eu",
            //     "jdatlq.eu",
            //     "jdqu.eu",
            //     "jeyp.eu",
            //     "jfxdx.us",
            //     "jhsvm.us",
            //     "jieqd.us",
            //     "jiknjg.eu",
            //     "jjrmbs.us",
            //     "jkeqvh.eu",
            //     "jksl.eu",
            //     "jlrvd.us",
            //     "jmlzok.us",
            //     "jndb.eu",
            //     "jnvcb.us",
            //     "joomep.us",
            //     "jpvrwn.eu",
            //     "jrmskq.us",
            //     "jrqdu.us",
            //     "jtchtn.us",
            //     "jtgomk.us",
            //     "jtmfn.us",
            //     "jujybx.us",
            //     "jvlhgb.us",
            //     "jwwz.eu",
            //     "jxotuf.us",
            //     "jyudpb.us",
            //     "kagq.eu",
            //     "kbkhgm.us",
            //     "kbogca.us",
            //     "kewerq.us",
            //     "kglc.eu",
            //     "khbd.eu",
            //     "khfs.eu",
            //     "khlts.us",
            //     "khmh.eu",
            //     "kjjk.eu",
            //     "kjyhdq.eu",
            //     "kmcj.eu",
            //     "kmef.eu",
            //     "kmosfv.eu",
            //     "kpqgic.eu",
            //     "kqkvce.eu",
            //     "krlgxs.eu",
            //     "kthgue.eu",
            //     "ktpq.eu",
            //     "kuue.eu",
            //     "kwnvmz.eu",
            //     "kxcl.eu",
            //     "kxdt.eu",
            //     "kybhyh.eu",
            //     "layb.eu",
            //     "lbhz.eu",
            //     "lbxo.eu",
            //     "leiw.eu",
            //     "lnrvix.eu",
            //     "lntf.eu",
            //     "luvakn.eu",
            //     "lvwn.eu",
            //     "lyybct.eu",
            //     "lzda.eu",
            //     "maxtrb.eu",
            //     "mchu.eu",
            //     "meqc.eu",
            //     "mjej.eu",
            //     "mjwvci.eu",
            //     "mmvd.eu",
            //     "mnbq.eu",
            //     "mnlv.eu"
            //   ]
            // }

            // create table with duplicate domains sample data
            let table = '<table style="width:100%;border:1px solid black;border-collapse:collapse;">';
            // add heading row message
            table += '<th style="border:1px solid black;border-collapse:collapse;">' + error.response.data.message + ' - ' + error.response.data.error + '</th>';
            table += '<tr style="border:1px solid black;border-collapse:collapse;">';
            table += '<th style="border:1px solid black;border-collapse:collapse;">Domain</th>';
            table += '</tr>';
            for (let i = 0; i < error.response.data.domains.length; i++) {
                table += '<tr style="border:1px solid black;border-collapse:collapse;">';
                table += '<td style="border:1px solid black;border-collapse:collapse;">' + error.response.data.domains[i] + '</td>';
                table += '</tr>';
            }
            table += '</table>';

          // show table as dialog
          // const dialog = window.open('', 'Duplicate Domains Found in Slims Reserve Domains Collection', 'width=600,height=400');
          // dialog.document.body.innerHTML = table;
            setShowDuplicateDomainsTable(table);
            setShowTextarea(true);
            setShowBackdrop(false);
            setDuplicateDomainsStatus(true)
            // alert('Error: There was an error uploading the file.');
            // scroll to tableRef
            setTimeout(() => {
              // scroll to tableRef - 100px
              window.scrollTo(0, tableRef.current.offsetTop - 300);
            }, 300);
            return;
          }


          console.error(error);
          alert('There was an error uploading the file.');
          setShowBackdrop(false);
        }
      };

      postData();
    }
  }, [fileSelected, fileContent]);

  useEffect(() => {
    const getData = async() => {
    
        try {
            const user = localStorage.getItem("user");
             await axios.get(process.env.REACT_APP_API_URL + `/users/${user}`, { withCredentials: true })

             setCampaignList([])
             // Get the REACT_APP_CAMPAIGN_SORT_CONFIG string from the .env file
            const campaignSortConfigString = process.env.REACT_APP_CAMPAIGN_SORT_CONFIG || '';
            
            // Split the string into an array using commas as the delimiter and trim each element
            const campaignSortConfigArray = campaignSortConfigString.split(',').map((campaign) => campaign.trim());

            //  console.log(campaignSortConfigArray)
             
             // Create a mapping object with custom sorting values based on the array index
            const campaignSortMap = {};
            campaignSortConfigArray.forEach((campaign, index) => {
              campaignSortMap[campaign] = index + 1;
            });
            
                   const campaignListMenu = await axios.get(process.env.REACT_APP_API_URL + "/campaign/?page=1&limit=100&sort=id&order=asc",  { withCredentials: true } )
   
                   const filteredCampaigns = campaignListMenu.data.results
                   .filter((val) => val.campaign !== "NULL")
                   .map((val) => val.campaign);
                   filteredCampaigns.sort((a, b) => {
                     return (campaignSortMap[a] || Number.MAX_SAFE_INTEGER) - (campaignSortMap[b] || Number.MAX_SAFE_INTEGER);
                   });
                   
   
                   setCampaignList(filteredCampaigns)
   
                  //  console.log(filteredCampaigns)

             setSetControllerParams((prev) => ({
              ...prev,
              campaign: filteredCampaigns[0],
              totalTmoMessages: process.env.REACT_APP_TOTALTMOMESSAGES,
              messagesPerHour: process.env.REACT_APP_MESSAGESPERHOUR,
              maxPerPortMessageLimit: process.env.REACT_APP_MAXPERPORTMESSAGELIMIT,
              perDomainMessageLimit: process.env.REACT_APP_PERDOMAINMESSAGELIMIT,
              totalDomainsPerSet: process.env.REACT_APP_TOTALDOMAINSPERSET,
              minimumSetThreshold: process.env.REACT_APP_MINIMUMSETTHRESHOLD,
              setsActiveAtOneTime: process.env.REACT_APP_SETSACTIVEATONETIME
          }))



            setloaded(true)
            
            
        } catch (error) {
            history.push("/")
        }
    }
    getData()
},[history]) 

if(loaded){
  return (
    <Container maxWidth="md" style={{ marginTop: '50px' }}>
      <Paper elevation={10} style={{ padding: '30px', textAlign: 'center' }}>
        <Typography variant="h4" style={{ marginBottom: 20 }} gutterBottom>
          Slims Domains - Set Controller
          <Divider style={{ margin: 10 }} />
        </Typography>

        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloudDownload />}
              style={{ textTransform: 'none', borderRadius: 10 }}
              fullWidth
            onClick={downloadUnusedCSV}
            >
              Download Unused
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeleteForever />}
              style={{ textTransform: 'none', borderRadius: 10 }}
              fullWidth
            onClick={deleteUnusedDomains}
            >
              Delete Unused
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="file-upload">
            <ButtonGroup style={{borderRadius: 10}}   variant="contained"  aria-label="split button">
              <Button
                variant="contained"
                color={fileSelected ? 'secondary' : 'primary'}
                className={fileSelected && uploadStatus === 'File Uploaded' ? classes.successButton : ''}
                component="span"
                startIcon={<CloudUpload />}
                style={{ textTransform: 'none', borderRadius: 10 }}
                fullWidth
              >
                {fileSelected ? selectedFileName : 'Upload Reserve'}
              </Button>
              <Button  disabled={(fileSelected ? false : true) || (uploadStatus ? true : false)} onClick={handleTickClick}>
                {fileSelected && uploadStatus === 'File Uploaded' ? <Check /> : <Add />}
             </Button>
            </ButtonGroup>
            </label>
            <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleUploadFile} />
            {/* {fileSelected && (
              <IconButton color="primary" onClick={handleTickClick}>
                <Add />
              </IconButton>
            )} */}
          </Grid>
        </Grid>
        <Box mt={3}>
      <Divider style={{ margin: '10px 0' }} />

      <form>
      <Grid container spacing={1}>
        <Grid item xs={2} />

          {/* create drop down menu */}
          <Grid item xs={4}>
            <TextField
              label="Campaign"
              variant="outlined"
              select
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { textAlign: 'center', backgroundColor:"#ede7f6" } }}
              SelectProps={{
                native: true,
            }}
              value={setControllerParams.campaign}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, campaign: e.target.value })}
            >
              {campaignList.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </Grid>

      <Grid item xs={4} >
            <TextField 
              label="Messages / Hour"
              variant="filled"
              type="number"
              value={setControllerParams.messagesPerHour}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, messagesPerHour: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true, style: {alignItems: 'center'}}}
              inputProps={{ style: {textAlign: 'center'} }}
              disabled
            />
          </Grid>
          <Grid item xs={4}/>
          </Grid>
          <Divider style={{margin:10}} />
        <Grid container spacing={2}>
        
          <Grid item xs={2}>
            <TextField 
              label="Total TMO Messages"
              variant="outlined"
              type="number"
              value={setControllerParams.totalTmoMessages}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, totalTmoMessages: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: {textAlign: 'center', marginTop:"7px", paddingTop:"18px"} }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField 
              label="Max Messages Per Port Limit"
              variant="outlined"
              type="number"
              value={setControllerParams.maxPerPortMessageLimit}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, maxPerPortMessageLimit: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: {textAlign: 'center', marginTop:"7px", paddingTop:"18px"} }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField 
              label="Per Domain Message Limit"
              variant="outlined"
              type="number"
              value={setControllerParams.perDomainMessageLimit}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, perDomainMessageLimit: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: {textAlign: 'center', marginTop:"7px", paddingTop:"18px"} }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField 
              label="Total Domains Per Set"
              variant="outlined"
              type="number"
              value={setControllerParams.totalDomainsPerSet}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, totalDomainsPerSet: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: {textAlign: 'center', marginTop:"7px", paddingTop:"18px"} }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField 
              label="Minimum Ports / Set Threshold"
              variant="outlined"
              type="number"
              value={setControllerParams.minimumSetThreshold}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, minimumSetThreshold: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: {textAlign: 'center', marginTop:"7px", paddingTop:"18px"} }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField 
              label="Total Sets To Be Active"
              variant="outlined"
              type="number"
              value={setControllerParams.setsActiveAtOneTime}
              onChange={(e) => setSetControllerParams({ ...setControllerParams, setsActiveAtOneTime: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: {textAlign: 'center', marginTop:"7px", paddingTop:"18px"} }}
            />
          </Grid>
        </Grid>

        
        <Button 
          style={{ borderRadius: 10 }} 
          variant="contained" 
          color="primary" 
          fullWidth 
          onClick={handleCreateSet}
        >
          Create Set
        </Button>
      </form>

      <Divider style={{ margin: '10px 0' }} />
    </Box>
    {
  (setCreationStatus === true) ? <><Alert severity="success" style={{marginTop:10}}>
  <strong>Success!</strong> Sets Created Successfully, Please See Server Response.
</Alert><Divider style={{ margin: '10px 0' }} /></> : (setCreationStatus === false) ? <><Alert severity="error" style={{marginTop:10}}>
<strong>Error!</strong> There was an error creating the sets, Please See Server Response.
</Alert><Divider style={{ margin: '10px 0' }} /></> : null

}

{
  (duplicateDomainsStatus === true) ? <><Alert severity="error" style={{marginTop:10}}>
  <strong>Error! Upload Reserve - </strong>There was an error uploading the file. Duplicate Domains Found!
</Alert><Divider style={{ margin: '10px 0' }} /></> : null
}

        <Collapse in={showTextarea} style={{ marginTop: '20px' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" gutterBottom>
              Server Response:
            </Typography>
            {serverResponse && (
            <textarea
              value={serverResponse}
              autoFocus
              rows={10}
              style={{
                width: '100%',
                padding: '10px',
                resize: 'none',
                fontFamily: 'inherit',
                textAlign: 'center',
                boxSizing: 'border-box',
                marginTop: '10px',
              }}
              readOnly
            />
          )}
          {showDuplicateDomainsTable && (
            // show showDuplicateDomainsTable as html
            <div ref={tableRef} dangerouslySetInnerHTML={{ __html: showDuplicateDomainsTable }} />
          )
          }
          </Box>
        </Collapse>
      </Paper>
      <Backdrop open={showBackdrop} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}else{
  return(
    <h1>Please Wait....</h1>
)
  }
};

export default SlimsDomains;
