import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PaginationComp from '@material-ui/lab/Pagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { Divider} from '@material-ui/core';
import PageLimitIcon from '@material-ui/icons/MenuBookTwoTone';
import TotalCountIcon from '@material-ui/icons/AssignmentTwoTone';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      
    },
  },
}));

const Pagination = (props) => {
    const handleChangePage = (event, newPage) => {
        props.handlePage(newPage)
      };

      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
    
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        
      };
    
      const handleClose = (event) => {
          if(event.target.id){
        props.handleLimit(event.target.id)
        // alert(event.target.id)
        setAnchorEl(null);
    }else{
        props.handleLimit(props.currentLimit)
        setAnchorEl(null);
        
    }
        // console.log(event.target.id)

      };
    
  const classes = useStyles();
  return (
    <div className={classes.root}>
{props.showHead==="true" &&
  <>
  <center>
<Button startIcon={<PageLimitIcon/>} color="secondary" variant="outlined" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        Page Limit : {props.currentLimit}
      </Button>
      <Menu
      
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose} id="1">1</MenuItem>
        <MenuItem onClick={handleClose} id="24">24</MenuItem>
        <MenuItem onClick={handleClose} id="50">50</MenuItem>
        <MenuItem onClick={handleClose} id="100" >100</MenuItem>
        <MenuItem onClick={handleClose} id="150" >150</MenuItem>
        <MenuItem onClick={handleClose} id="200" >200</MenuItem>
      </Menu>
      

      {/* <TextField inputProps={{min: 0, style: { textAlign: 'center', color:"#3f51b5", fontSize:"50", fontWeight:"bolder" }}} 
       disabled value={`Total Records : ${props.totalCounts}`} variant="standard"></TextField> */}
       <Button startIcon={<TotalCountIcon/>} color="primary" variant="outlined"><b>{`Total: ${props.totalCounts}`}</b></Button>
       
       </center>
       </>
       }
      <PaginationComp variant="outlined" color="primary" size="medium" siblingCount={3}  count={props.pages} page={props.currentPage} onChange={handleChangePage}/>
      <Divider/>
    </div>
  );
}

export default Pagination