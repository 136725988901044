import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid} from '@material-ui/core';
import FormDialog from "../FormDialog"
import axios from "axios"
import { useHistory } from "react-router-dom"
import ProgrssBar from '../ProgressBar';
import Pagination from "../Pagination"
import CarrierMapDatas from "../CarrierMapDatas"
import Alert from '@material-ui/lab/Alert';
import grey from "@material-ui/core/colors/grey"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.grey[300],
    },
    paper: {
        marginRight: theme.spacing(2),
        border: "200px"
        // backgroundColor: '#ffab40'
    },
}));

const CarrierMap = () => {
    document.body.style.backgroundColor = grey[300];
    const classes = useStyles();
    const history = useHistory()
    const [loaded, setloaded] = useState(false)
    const [data, setData] = useState({
        info: {},
        next: {},
        results: []

    })

    const [progress, setProgress] = useState(false)
    const [page, setPage] = useState({ pageNumber: "1", limit: "24" })

    function handlePage(val) {
        setPage({ ...page, pageNumber: val })
    }

    function handleLimit(val) {
        if (page.limit !== val) {
            setPage({ ...page, pageNumber: "1", limit: val })
        }
    }

    const [checkDel, setCheckDel] = useState("")
    const [checkAdd, setCheckAdd] = useState("")
    const [refresh, setRefresh] = useState("")

    const delStatus = (status, refresh) => {
        setCheckDel(status)
        setRefresh(refresh)
        setTimeout(() => {
            setCheckDel("")
        }, 5000);
    }

    const addStatus = (status) => {
        setCheckAdd(status)
        
    }

    useEffect(() => {
        document.title = "GMP - Please Wait..."
        let source = axios.CancelToken.source()
        const getData = async () => {

            // const headers = {
            //     withCredentials: true
            // }
            try {
                // const user = localStorage.getItem("user");
                setProgress(true)

                const getDataResult = await axios.get(process.env.REACT_APP_API_URL + `/carriermap/?page=${page.pageNumber}&limit=${page.limit}&sort=id&order=asc`, { cancelToken: source.token, withCredentials: true })
                // console.log(getDataResult)

                setData({
                    info: getDataResult.data.info,
                    next: getDataResult.data.next,
                    results: getDataResult.data.results

                })
                setProgress(false)
                setloaded(true)
                document.title = "GMP - Carrier-Mapping"
                // setloaded(true)
                // console.log(getDataResult)

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.info("Last Request Was Cancelled")
                } else {
                    if (error.response.status === 401) {
                        history.push("/")
                    }
                    // console.log(error)
                    setProgress(false)
                    setData({
                        info: { "counts": 0 }
                    })
                }
            }
        }
        getData()

        return () => {
            // console.log("unmount")
            source.cancel()
        }

    }, [history, page, checkDel, checkAdd, refresh])


    // return focus to the button when we transitioned from !open -> open

    if (loaded) {
        // console.log(data.results)
        return (
            <React.Fragment>
            { progress && <ProgrssBar />}
                {(data.info.counts > 0) && <Pagination showHead="true" pages={data.info.pages} totalCounts={data.info.counts} handlePage={handlePage} handleLimit={handleLimit} currentLimit={page.limit} currentPage={data.info.currentPageNumber} />}
                {

                    ((checkDel === "yes") && <Alert severity="success" >Successfully Deleted !</Alert>) ||
                    ((checkDel === "error") && <Alert severity="error">Error Occured - Unable To Delete !</Alert>
            
                    )

                }
            <div className={classes.root}>
                
                <Grid container justify="center" spacing={1} style={{ paddingLeft: "20px", paddingRight: "5px", paddingTop: "20px" }}>

                
                { data.results.map((val) => {
                    
                return(
                
                    <CarrierMapDatas key={val._id} carrierMapId={val._id} carrier={val.carrier} textMap={val.textOption} domainMap={val.map} delStatus={delStatus} />
                    
                    )
                    })
                    }
                    
                </Grid>
                
                <FormDialog addStatus={addStatus} />
            </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <ProgrssBar />
                <h1>Please Wait...</h1>
            </React.Fragment>
        );

    }
}
export default CarrierMap