import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ToolTip from "./ToolTip"
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/CancelPresentationTwoTone';
import AddIcon from '@material-ui/icons/AddBoxTwoTone';
import axios from "axios"
import Alert from '@material-ui/lab/Alert';

export default function FormDialog(props) {
  const [open, setOpen] = useState(false);

  const [carrier, setCarrier] = useState("")
  const [textMap, setTextMap] = useState("")
  const [domainMap, setDomainMap] = useState("")
  const [addError, setAddError] = useState(false)
  const [postData, setPostData] = useState({});
  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const submitHandleClose = async () => {

    
      const headers = {
        withCredentials: true
      }
      
      try {
  
        await axios.post(process.env.REACT_APP_API_URL + `/carriermap`, postData, headers)
        // console.log(getDataResult)
        setAddError(false)
        setOpen(false);
        props.addStatus(Math.random())
  
      } catch (error) {
  
        if (error.response.status === 401) {
          return (history.push("/"))
        }
        setAddError(true)
      }
    
   
  };

  useEffect(() => {
    let mapArray = []
    mapArray = domainMap.split(",")
    setPostData({
      "carrier": carrier,
      "textOption": textMap,
      "map": mapArray
    })
  }, [carrier, domainMap, textMap])


// useEffect(() => {
  
//   if(!postData.carrier){
//     return 
//   }
  
    
//     postDataApi()
    
//   // })

// }, [history, postData, refresh])

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <ToolTip openForm={() => handleClickOpen} />

      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adding Carrier Map</DialogTitle>
        {addError && <Alert severity="error">Error Occured - Unable To Add Carrier Map !</Alert>}
        <form method="post" onSubmit={(e) => { e.preventDefault(); submitHandleClose() }}>
          <DialogContent>
            <DialogContentText>

              <li>Make Sure "Carrier" Name Matches "SLIMS"</li>
              <li> Make Sure "Client" Using Same Carrier "Text Map"</li>
              <li>Make Sure "Domain Map" Values Matches "Carrier Lookup"</li>
              <li> Note :  "Domain Map" Values Each in "Seperate Line"</li>

            </DialogContentText>

            <TextField
              autoComplete="off"
              inputProps={{style: {textTransform: "uppercase"}}}
              autoFocus
              required
              margin="dense"
              id="name"
              label="Carrier Mapping"
              type="text"
              onChange={(e) => setCarrier(e.target.value.toUpperCase())}
              fullWidth
            />
            <TextField
              autoComplete="off"
              inputProps={{style: {textTransform: "uppercase"}}}
              required
              margin="dense"
              id="name"
              label="Text Map"
              type="text"
              onChange={(e) => setTextMap(e.target.value.toUpperCase())}
              fullWidth
            />
            <TextField
              autoComplete="off"
              inputProps={{style: {textTransform: "lowercase"}}}
              required
              multiline
              rows={3}
              variant="filled"
              margin="dense"
              id="name"
              label="Domain Map Values Each in Seperate Line"
              type="text"
              onChange={(e) => {
                let myValue = e.target.value.replace(/\n/g, ",").toLowerCase()
                setDomainMap(myValue)
              }}

              fullWidth
            />

          </DialogContent>

          <DialogActions>
            <Button startIcon={<DeleteIcon />} onClick={handleClose} variant="contained" color="secondary">
              Discard
          </Button>
            <Button startIcon={<AddIcon />} type="submit" variant="contained" color="primary">
              Save
          </Button>
          </DialogActions>
        </form>
      </Dialog>

    </div>
  );
}
