import React, { useState } from 'react'
import {Grid, TableRow, TextField, TableCell, Button, Checkbox} from '@material-ui/core'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { useClipboard } from 'use-clipboard-copy';
import { toast } from 'react-toastify';
import axios from "axios"
import {useHistory} from "react-router-dom"
import { useConfirm } from 'material-ui-confirm';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import DeleteIcon from '@material-ui/icons/CancelPresentationTwoTone';
import AddIcon from '@material-ui/icons/AddBoxTwoTone';

const CampaignsRows = (props) => {
    const confirm = useConfirm()
    const history = useHistory()

    const [secretSize, setSecretSize] = useState(80)
    const [dlrSize, setDlrSize] = useState(183)
    const [moSize, setMoSize] = useState(183)
    const [backWashSize, setBackWashSize] = useState(183)


    const [applyButton, setApplyButton] = useState(true)
    const [dlr, setDlr] = useState(props.dlr)
    const [mo, setMo] = useState(props.mo)
    const [bkwsh, setBkWsh] = useState(props.bkwsh)
    const [status, setStatus] = useState(props.status)



    const secretFunc = (e) => {
        toast.dismiss()
        props.updateSetCopied(true)
        e.target.type = "text"
        setSecretSize(420)
        clipboard.copy(e.target.value)
        // if (!props.copied) {
        setTimeout(() => {
            e.target.type = "password"; setSecretSize(80);

        }, 5000);
        // }



    }

    const notify = () => toast.dark("Secret Copied !", {
        onClose: () => props.updateSetCopied(false)
    });

    const clipboard = useClipboard({
        copiedTimeout: 600, // timeout duration in milliseconds
        onSuccess() {

            notify()


        },
    });


    const update = async (id) => {

        const upDatePacket = {
            dlrEndPoint: dlr,
            moEndPoint: mo,
            backWashEndPoint: bkwsh,
            status: status
        }

        const headers = {
            withCredentials: true
        }

        try {
            await axios.put(process.env.REACT_APP_API_URL + `/campaign/${id}`, upDatePacket,  headers )
            setApplyButton(true) 
            props.handleAddDelStatus("success", "Successfully Updated !")
            props.handleUpdate(true)
        } catch (error) {
            if (error.response.status === 401) {
                return (history.push("/"))
            }
            props.handleAddDelStatus("error", "Error Occured While Updating !")
        }

    }


    const deleteCampaign = async (id) => {

        confirm({ description: 'This action is permanent!' })
        .then(async() => {
        const headers = {
            withCredentials: true
        }

        try {
            await axios.delete(process.env.REACT_APP_API_URL + `/campaign/${id}`, headers)

            props.handleAddDelStatus("info", "Successfully Deleted !")
            props.handleUpdate(true)
        } catch (error) {
            if (error.response.status === 401) {
                return (history.push("/"))
            }
            props.handleAddDelStatus("error", "Error Occured While Deleteing !")
        }

    }).catch(() => console.log("Delete Cancelled"))
    }



    const updateSecret = async (id) => {

        confirm({ description: 'This Will Regenerate Secret !' })
        .then(async() => {
        const headers = {
            withCredentials: true
        }

        try {
            await axios.get(process.env.REACT_APP_API_URL + `/campaign/secret/${id}`, headers)

            props.handleAddDelStatus("info", "Successfully Regenerated Secret !")
            props.handleUpdate(true)
        } catch (error) {
            if (error.response.status === 401) {
                return (history.push("/"))
            }
            props.handleAddDelStatus("error", "Error Occured While Regenerating Secret !")
        }

    }).catch(() => console.log("Regenerating Secret Cancelled"))
    }


    return (
        <React.Fragment>
            <TableRow hover>
                <TableCell>
                    <Button size="small" startIcon={<DeleteIcon />} onClick={() => deleteCampaign(props.id)} variant="contained" color="secondary">Delete</Button>
                                &nbsp;
                                <Button size="small"  startIcon={<AddIcon />} onClick={() => update(props.id)} disabled={applyButton} variant="contained" color="primary">Apply</Button>
                </TableCell>
                <TableCell>{props.createDate}</TableCell>
                <TableCell>
                    {/* <TextField  inputProps={{style: {textAlign: 'center'}, readOnly: true }} value={props.campaign} style={{ width: "125px" }} /> */}
                    {props.campaign}
                </TableCell>
                <TableCell >
                <Grid style={{display:"flex"}} item  xs>
                    <TextField
                        disabled={true}
                        
                        style={{ width: secretSize }}
                        onClick={secretFunc}
                        type="password" value={props.secret} />

                        <RotateLeftRoundedIcon color="primary" onClick={() => updateSecret(props.id)} style={{width:21, cursor:"pointer"}} />
                        
                    </Grid>
                </TableCell>


                <TableCell>
                    <TextField
                        style={{ width: dlrSize }}
                        onFocus={() => setDlrSize(700)}
                        onBlur={() => setDlrSize(183)}
                        onChange={(e) => { setDlr(e.target.value); setApplyButton(false) }}
                        value={dlr} />
                </TableCell>
                <TableCell>
                    <TextField
                        style={{ width: moSize }}
                        onFocus={() => setMoSize(700)}
                        onBlur={() => setMoSize(183)}
                        onChange={(e) => { setMo(e.target.value); setApplyButton(false) }}
                        value={mo} />
                </TableCell>
                <TableCell>
                    <TextField
                        style={{ width: backWashSize }}
                        onFocus={() => setBackWashSize(700)}
                        onBlur={() => setBackWashSize(183)}
                        onChange={(e) => { setBkWsh(e.target.value); setApplyButton(false) }}
                        value={bkwsh} />
                </TableCell>
                <TableCell>
                    <Checkbox onChange={(e) => { setStatus(e.target.checked); setApplyButton(false) }} checked={status} icon={<ToggleOffIcon style={{ color: "red", fontSize: 45 }} />} checkedIcon={<ToggleOnIcon style={{ color: "green", fontSize: 45 }} fontSize="large" />} />
                </TableCell>
            </TableRow>

        </React.Fragment>
    )
}

export default CampaignsRows
