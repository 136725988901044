import React from 'react';

import { Box, Card, Button, Typography, Grid, CardContent, CardActions, Divider, CardHeader, Avatar } from '@material-ui/core';

import PhoneIcon from "@material-ui/icons/PhoneIphoneTwoTone"
import MessageIcon from "@material-ui/icons/SmsTwoTone"
import RouterRoundedIcon from '@material-ui/icons/RouterRounded';
import SimCardTwoToneIcon from '@material-ui/icons/SimCardTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import { orange, deepOrange, purple } from "@material-ui/core/colors/"
import ReadMoreIcon from '@material-ui/icons/MoreHorizTwoTone';


const MessagesCards = (props) => {
    
    

    return (
        
            
                <Grid item lg={3} sm={6} xs={12}>
                    <Card component={Box} boxShadow={10} style={{marginBottom : "60px"}}>
                        <CardHeader style={{ backgroundColor: "#e3f2fd" }}
                            subheaderTypographyProps={{ variant: 'subtitle1' }}

                            title={props.dataId}

                            subheader={props.dateTime}
                            avatar={
                                <Avatar style={{ backgroundColor: purple[300] }}><b>{ props.msgType }</b></Avatar>
                            }
                        />
                        <Divider />
                        <CardContent >

                            <Typography align="center" variant="h5"><PhoneIcon style={{ color: deepOrange[500] }} /> { props.phone }</Typography>
                            <Divider />
                            <Typography align="center" variant="subtitle1">
                                <MessageIcon color="primary" /> &nbsp;{props.text}
                             </Typography>
                            <Divider />
                            <Typography align="center" variant="subtitle1">
                                <RouterRoundedIcon color="primary" /> &nbsp;{ props.simBoxPort } &nbsp; <SimCardTwoToneIcon style={{ color: orange[800] }} /> { props.port}
                             </Typography>
                            <Divider />
                            <Typography style={{ textTransform: "capitalize"  }} align="center" variant="body1"><InfoTwoToneIcon color="secondary" /> { props.dlrStatus || "Pending" }</Typography>

                        </CardContent>


                        <CardActions style={{ backgroundColor: "#3f51b5" }} >
                            <Button startIcon={<ReadMoreIcon/>} fullWidth variant="contained" color="primary">More Details</Button>
                        </CardActions>
                    </Card>
                </Grid>




            

        
    );
}

export default MessagesCards