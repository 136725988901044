import { TableCell, Button, Checkbox, Divider, FormControlLabel, FormGroup, Menu, MenuItem, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import axios from "axios"
import {useHistory} from "react-router-dom"


const PortMenus = (props) => {
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [thisPorts, setThisPorts] = useState(props.selectedPorts)
    const [selectAllButtonText, setSelectAllButtonText] = useState("Select All")




    const addRemove = (event) => {

        const { value } = event.target


        if (thisPorts.includes(value)) {
            // alert("will remove")
            setThisPorts(thisPorts.filter(item => item !== value));

            setSelectAllButtonText("Select All")


        } else {
            // alert("will add")
            setThisPorts([...thisPorts, value]);
            setSelectAllButtonText("Select All")


        }


        // setApplyButton(false)
        props.applyButtonFunc(false)


    }


    // const applyChanges = () => {


    //     handleClose()
    //     props.applyButtonFunc(true)
    //     props.handlePortUpdateRefresh(thisPorts)


    // }

    // useEffect(() => {
    //     props.finalApply(thisPorts)
    // }, [props.slims, thisPorts])

const {handleUpdate, handleAddDelStatus} = props
    useEffect(() => {
        if (!props.slims) return



        const updatePorts = async() => {
            const postData = {

                "Campaign": props.slims.Campaign,
                "AllowedBox": props.slims.AllowedBox,
                "AllowedPorts": thisPorts
                ,
                "Carrier_HardLimit": {
                    "ATT": {
                        "Limit": props.slims.AttCountsLimit,
                        "Duration": props.slims.AttDuration
                    },
                    "VZW": {
                        "Limit": props.slims.VzwCountsLimit,
                        "Duration": props.slims.VzwDuration
                    },
                    "TMO": {
                        "Limit": props.slims.TmoCountsLimit,
                        "Duration": props.slims.TmoDuration
                    },
                    "SPRINT": {
                        "Limit": props.slims.SprintCountsLimit,
                        "Duration": props.slims.SprintDuration
                    },
                    "ALLOTHER": {
                        "Limit": props.slims.AllotherCountsLimit,
                        "Duration": props.slims.AllotherDuration
                    }
                }
            }
    
            // console.log(postData)
            const headers = {
                withCredentials: true
              }
              
              try {
          
                await axios.put(process.env.REACT_APP_API_URL + `/slims`, postData, headers)
                // console.log(getDataResult)
                // setAddError(false)
                // setOpen(false);
                // props.handleUpdate(true)
                // props.handleAddDelStatus("info")
                handleUpdate(true)  
                handleAddDelStatus("success", "Successfully Updated !")
              } catch (error) {
          
                if (error.response.status === 401) {    
                  return (history.push("/"))
                }
                
                handleAddDelStatus("error", "Error Occured While Updating !")
                // setAddError(true)
              }
    
    
        }
       
        updatePorts()

    }, [props.slims, thisPorts, history, handleUpdate, handleAddDelStatus])


    useEffect(() => {
        setThisPorts(props.selectedPorts);
        if (props.selectedPorts.length === 64) {
            setSelectAllButtonText("Remove All")
        }
    }, [props.selectedPorts])




    return (
        <React.Fragment>
            <TableCell>
                <Button component={Paper} elevation={5} color="primary" variant="outlined" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    {`Active Ports : ${props.selectedPorts.length}`}
                </Button>

                <Menu
                    id="simple-menu1"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >


                    {/* <Button disabled={applyButton} onClick={() => applyChanges(`${props.campaign}-${props.allowedBox}`)} fullWidth  color="secondary" variant="contained">
                        Apply Changes
                        </Button> */}

                    <Divider />

                    <Divider />
                    <MenuItem>
                        <Button color="primary" variant="contained" onClick={() => {
                            props.applyButtonFunc(false)
                            if (selectAllButtonText === "Select All") {
                                setThisPorts(props.ports)
                                setSelectAllButtonText("Remove All")
                            } else {
                                setThisPorts([])
                                setSelectAllButtonText("Select All")
                            }

                        }}>
                            {selectAllButtonText}
                        </Button>
                    </MenuItem>
                    <Divider />

                    <Divider />

                    {
                        props.ports.map((val, ind) => {

                            return (
                                <MenuItem key={ind}><FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={val}
                                        control={<Checkbox

                                            checked={thisPorts.includes(val) ? true : false} color="primary" />}
                                        label={"Port - " + val}
                                        labelPlacement="start"
                                        onClick={addRemove}

                                    />

                                </FormGroup></MenuItem>
                            )

                        })
                    }





                    {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>


            </TableCell>
        </React.Fragment>
    )
}

export default PortMenus
