import React from 'react'
import SideBarList from "./SideBarList"
import { SwipeableDrawer, List, Box, Divider, makeStyles } from "@material-ui/core"
import teal from "@material-ui/core/colors/cyan";

const useStyles = makeStyles({
    paper: {
      background: teal[700],
      color: 'white'
    }
  });
const SideBar = (props) => {
const classes = useStyles()
    

    return (
        <React.Fragment>
            
            <SwipeableDrawer classes={{ paper: classes.paper }} variant="temporary" anchor="left" open={props.open} onOpen={() => { }} onClose={props.close}>
                <Box textAlign="center" py={2}>
                    Action Center
                </Box>
                <Divider />
                <List onClick={props.close}>
                    {props.Menus.map((val, ind) => {
                        return <SideBarList key={ind} menu={val} />
                    })}
                </List>
            </SwipeableDrawer>
        </React.Fragment>
    )
}

export default SideBar
