import React, { useState, useEffect } from 'react'
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TextField, TableCell, Divider, Button } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"
import primaryColor from "@material-ui/core/colors/indigo"
import { Flip, ToastContainer } from 'react-toastify';
import ProgressBar from "../ProgressBar"
import Pagination from "../Pagination"
import CampaignsRows from "../CampaignsRows"
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import CampaignsFormDialog from "../CampaignsFormDialog"
import axios from "axios"
import { useHistory } from "react-router-dom"
import Alert from '@material-ui/lab/Alert';

const TableHeaderCell = withStyles({
    root: {
        background: primaryColor[500],
        borderRadius: 8,
        border: 0,
        color: 'white',
        height: 45,

        boxShadow: '0 3px 5px 2px rgba(28, 10, 120, .3)',



    }
})(TableCell);


const Campaigns = () => {

    const [update, setUpdate] = useState("")
    const [copied, setCopied] = useState(false)
    const [progress, setProgress] = useState(false)

    const [campaignList, setCampaignList] = useState([])

    const [filter, setFilter] = useState({})

    const [page, setPage] = useState({ pageNumber: "1", limit: "24" })

    const [loaded, setloaded] = useState(false)

    const [data, setData] = useState({
        info: {},
        next: {},
        results: []

    })

    const history = useHistory()

    function handlePage(val) {
        setPage({ ...page, pageNumber: val })
    }

    function handleLimit(val) {
        if (page.limit !== val) {
            setPage({ ...page, limit: val })
        }
    }

    const updateSetCopied = (val) => {
        setCopied(val)
    }

    document.body.style.backgroundColor = grey[300];


    


    
    useEffect(() => {

        document.title = "GMP - Please Wait..."
        let source = axios.CancelToken.source()
        const getData = async () => {

            const headers = {
                withCredentials: true
            }
            try {
                // const user = localStorage.getItem("user");
                setProgress(true)

                setCampaignList([])
                const campaignListMenu = await axios.get(process.env.REACT_APP_API_URL + "/campaign",  headers )

            
                campaignListMenu.data.results.map((val) => {
                    return setCampaignList((pre) => [...pre, val.campaign])
                })


                const getDataResult = await axios.post(process.env.REACT_APP_API_URL + `/campaign/?page=${page.pageNumber}&limit=${page.limit}&sort=id&order=desc`, filter, { cancelToken: source.token, withCredentials: true })
                // console.log(getDataResult)

                setData({
                    info: getDataResult.data.info,
                    next: getDataResult.data.next,
                    results: getDataResult.data.results

                })
                setProgress(false)
                setloaded(true)
                document.title = "GMP - Campaigns"
                // setloaded(true)
                // console.log(getDataResult)

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.info("Last Request Was Cancelled")
                } else {
                    if (error.response.status === 401) {
                        history.push("/")
                    }
                    // console.log(error)
                    setProgress(false)
                    setData({
                        info: { "counts": 0 }
                    })
                }
            }
        }
        getData()

        return () => {
            // console.log("unmount")
            source.cancel()

            // console.clear()
        }

    }, [history, filter, page, update])


    
        
        



    const [showDelBannerType, setShowDelBannerType] = useState("")
    const [delStatus, setDelStatus] = useState(false)
    const [delStatusText, setDelStatusText] = useState("")

    const handleAddDelStatus = (showBanner, updateText = undefined) => {

        setShowDelBannerType(showBanner)
        setDelStatus(true)
        if (updateText) {
            setDelStatusText(updateText)
        } else {
            if (showBanner === "success") {
                setDelStatusText("Succesfully Deleted !")
            } else if (showBanner === "info") {
                setDelStatusText("Succesfully Added !")
            }
            else {
                setDelStatusText("Error Occured While Deleting !")
            }
        }

        setTimeout(() => {
            setDelStatus(false)
            setDelStatusText("")

        }, 5000);

    }

    const handleUpdate = (val) => {
        setUpdate(`${Math.random()}_${val.toString()}`)
    }


    const filterUpdate = (event) => {

        if(event.target.value===""){
            filter[event.target.name] = undefined
            setFilter({ ...filter})
            return
        }

        
        setFilter({ ...filter, [event.target.name]: event.target.value })

        
    }
    
    if (loaded) {


        return (
            <React.Fragment>
                { progress && <ProgressBar />}
                {progress===false && delStatus && <Alert severity={showDelBannerType} >{delStatusText}</Alert>}
                <Grid spacing={2} container style={{ padding: 15 }} >
                    <Grid item>

                        <TreeView component={Paper} elevation={15} >
                            <TreeItem nodeId="1" style={{ backgroundColor: grey[300] }} label={<Button fullWidth startIcon={<FilterListIcon />} variant="outlined" color="primary">Filters</Button>}>
                                <Grid container style={{ paddingTop: 5 }}>


                                    <Grid item  >
                                        <TextField select variant="outlined"
                                            name="campaign"
                                            onChange={filterUpdate}
                                            value={filter.campaign}
                                            label="Campaign"
                                            placeholder="Campaign"
                                            style={{ width: "140px" }}
                                            SelectProps={{
                                                native: true,
                                            }}>
                                            <option value=""></option>
                                            {
                                                campaignList.map((val) => {
                                                    return <option key={val} value={val}>{val}</option>  
                                                })
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item  >
                                        <TextField select variant="outlined"
                                            // onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                                            name="status"
                                            onChange={filterUpdate}
                                            label="Status"
                                            placeholder="Status"
                                            style={{ width: "140px" }}
                                            SelectProps={{
                                                native: true,
                                            }}>
                                            <option></option>
                                            <option value={true}>Active</option>
                                            <option value={false}>Inactive</option>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </TreeItem>
                        </TreeView>

                    </Grid>
                </Grid>

                <Divider />

                {/* <Pagination showHead="true" pages={100} totalCounts={200} handlePage="" handleLimit="" currentLimit={1} currentPage={1} /> */}
                {(data.info.counts > 0) && <Pagination showHead="true" pages={data.info.pages} totalCounts={data.info.counts} handlePage={handlePage} handleLimit={handleLimit} currentLimit={page.limit} currentPage={data.info.currentPageNumber} />}
                <Divider />
                <br />
                <TableContainer component={Paper} elevation={20}>

                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Delete / Apply ?</TableHeaderCell>
                                <TableHeaderCell>Creation Date</TableHeaderCell>
                                <TableHeaderCell>Camapign</TableHeaderCell>
                                <TableHeaderCell>Secret</TableHeaderCell>
                                <TableHeaderCell>DLR Endpoint</TableHeaderCell>
                                <TableHeaderCell>MO Endpoint</TableHeaderCell>
                                <TableHeaderCell>BackWash Endpoint</TableHeaderCell>
                                <TableHeaderCell>Status</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.results.map((val) => {
                                    return (
                                        <CampaignsRows
                                            key={val._id}
                                            id={val._id}
                                            updateSetCopied={updateSetCopied}
                                            copied={copied}
                                            createDate={val.date}
                                            campaign={val.campaign}
                                            secret={val.secret}
                                            dlr={val.dlrEndPoint ? val.dlrEndPoint : ""}
                                            mo={val.moEndPoint ? val.moEndPoint : ""}
                                            bkwsh={val.backWashEndPoint ? val.backWashEndPoint : ""}
                                            status={val.status}
                                            handleAddDelStatus={handleAddDelStatus}
                                            handleUpdate={handleUpdate}
                                        />
                                    )
                                })

                            }
                        </TableBody>
                    </Table>
                    <CampaignsFormDialog handleUpdate={handleUpdate} handleAddDelStatus={handleAddDelStatus} />
                    <ToastContainer position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover={false}
                        transition={Flip}
                    />

                    <Pagination pages={data.info.pages} showHead="false" totalCounts={data.info.counts} handlePage={handlePage} handleLimit={handleLimit} currentLimit={page.limit} currentPage={data.info.currentPageNumber} />
                </TableContainer>


            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                <ProgressBar />
                <h1>Please Wait...</h1>
            </React.Fragment>
        );
    }
}

export default Campaigns
