import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ToolTip from "./ToolTip"
import DeleteIcon from '@material-ui/icons/CancelPresentationTwoTone';
import AddIcon from '@material-ui/icons/AddBoxTwoTone';
import Alert from '@material-ui/lab/Alert';
import axios from "axios"
import { useHistory } from "react-router-dom"


const PortFormDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [addError, setAddError] = useState(false)
  const [campaign, setCampaign] = useState("")
  const [simBox, setSimBox] = useState("")
  const [errorText, setErrorText] = useState("")
  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true);
    setErrorText("")
    setAddError(false)
    setCampaign("")
    setSimBox("")
  };

  const handleClose = () => {
    setOpen(false);
    setErrorText("")
    setAddError(false)
    setCampaign("")
    setSimBox("")
  };


  const submitHandleClose = async () => {

    let postData = []
    for (let portsRange = 1; portsRange <= 64; portsRange++) {

      let allowedPort = portsRange.toString()

      postData.push(
        {
          "SLIMS": {
            "Status": true,
            "Details": [
              {
                "Campaign": campaign,
                "AllowedBox": simBox,
                "AllowedPort": allowedPort
              }
            ],
            "Carrier_HardLimit": {
              "ATT": {
                "Limit": 10000000,
                "Duration": 360000
              },
              "VZW": {
                "Limit": 10000000,
                "Duration": 360000
              },
              "TMO": {
                "Limit": 10000000,
                "Duration": 360000
              },
              "SPRINT": {
                "Limit": 10000000,
                "Duration": 360000
              },
              "ALLOTHER": {
                "Limit": 10000000,
                "Duration": 360000
              }
            }
          }
        }
      )

    }


    // console.log(postData)


    const headers = {
      withCredentials: true
    }

    try {

      await axios.post(process.env.REACT_APP_API_URL + `/slims`, postData, headers)
      // console.log(getDataResult)
      setAddError(false)
      setOpen(false);
      props.handleUpdate(true)
      props.handleAddDelStatus("info")

    } catch (error) {

      if (error.response.status === 401) {
        return (history.push("/"))
      }
      else if (error.response.status === 409) {
        setErrorText(`Conflict - On Processing : \n Campaign : ${error.response.data.SLIMS.Details[0][0]["Campaign"]} \n AllowedBox : ${error.response.data.SLIMS.Details[0][0]["AllowedBox"]} \n Already Exist !!`)
      }
      else {
        setErrorText("Error Occured - Unable To Add Campaign !")
      }

      setAddError(true)
    }



  };

  const [campaignList, setCampaignList] = useState([])
  const [simBoxes, setSimBoxes] = useState([])

  useEffect(() => {

    const getCampains = async() => {
      const headers = {
        withCredentials: true
      }
      try {
        setCampaignList([])
        const campaignListMenu = await axios.get(process.env.REACT_APP_API_URL + "/campaign", headers)
  
        campaignListMenu.data.results.map((val) => {
          return setCampaignList((pre) => [...pre, val.campaign])
        })

        const getSimBoxesList = await axios.get(process.env.REACT_APP_API_URL + "/gateway", headers)

        setSimBoxes(JSON.parse( getSimBoxesList.data.SIM_BOXES ))


      } catch (error) {
  
      }
    }
    getCampains()
  }, [])



  return (
    <div>
      <ToolTip openForm={() => handleClickOpen} />

      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign SimBox For Campaign</DialogTitle>
        <div style={{ whiteSpace: "pre-wrap" }}>{addError && <Alert severity="error">{errorText}</Alert>}</div>
        <form method="post" onSubmit={(e) => { e.preventDefault(); submitHandleClose() }}>
          <DialogContent>
            <DialogContentText>

              <li>Please Select Campaign Name</li>
              <li>Please Select SimBox To Be Allowed For This Campaign</li>


            </DialogContentText>

            <TextField
            select
              autoComplete="off"
              SelectProps={{
                native: true,
              }}
              autoFocus
              required
              margin="dense"
              id="campaign"
              label="Campaign"
              type="text"
              onChange={(event) => setCampaign(event.target.value.toUpperCase())}
              value={campaign}
              fullWidth
            >
              <option value=""></option>
              {
                campaignList.map((val) => {
                  return <option key={val} value={val}>{val}</option>
                })
              }

              </TextField>
            <TextField
              select
              inputProps={{ style: { textTransform: "uppercase" } }}
              required
              margin="dense"
              id="simbox_port"
              label="Simbox Port"
              type="number"
              SelectProps={{
                native: true,
              }}
              fullWidth
              value={simBox}
              onChange={(event) => setSimBox(event.target.value)}
            >
              <option></option>
              {/* <option select="8012">8012</option>
              <option select="8013">8013</option>
              <option select="8014">8014</option>
              <option select="8015">8015</option>
              <option select="8016">8016</option> */}

              {
                simBoxes.map((val) => {
                  return <option key={val} value={val}>{val}</option>
                })
              }

            </TextField>


          </DialogContent>

          <DialogActions>
            <Button startIcon={<DeleteIcon />} onClick={handleClose} variant="contained" color="secondary">
              Discard
          </Button>
            <Button startIcon={<AddIcon />} type="submit" variant="contained" color="primary">
              Save
          </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

export default PortFormDialog
