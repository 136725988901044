import React from 'react'
import { MenuList, MenuItem, Paper, Box, Button, Divider, Grid, ListItemText, Typography } from '@material-ui/core';
import CarrierIcon from '@material-ui/icons/SettingsInputAntennaTwoTone';
import TextMap from '@material-ui/icons/TextFieldsTwoTone';
import DomainMap from '@material-ui/icons/AccountTreeTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteForeverTwoTone';
import MapValueTextIcon from '@material-ui/icons/SubdirectoryArrowRightTwoTone';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom"
import axios from "axios"
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
        border: "200px"
        // backgroundColor: '#ffab40'
    },
}));
const CarrierMapDatas = (props) => {
    const classes = useStyles();
    const confirm = useConfirm();
    const history = useHistory()

    // const [delStatus, setDelStatus] = useState("")

    const delteItem = async (id) => {
        confirm({ description: 'This action is permanent!' })
        .then( async() => {
        const headers = {
            withCredentials: true
        }
        try {

            await axios.delete(process.env.REACT_APP_API_URL + `/carriermap/${id}`, headers)
            // console.log(getDataResult)

            props.delStatus("yes", Math.random())
            
            
        } catch (error) {
            
                if (error.response.status === 401) {
                    return(history.push("/"))
                }
                props.delStatus("error")
                // console.log(error)
                
        }
    } )
    .catch(() => console.log("Delete Cancelled"))
    }

    return (
        <Grid item>

            <Paper component={Box} boxShadow={10} className={classes.paper}>
                <MenuList  >
                    <ListItemText style={{

                        color: "white",
                        textAlign: "center",

                    }}

                        primary={
                            <Button color="primary" fullWidth variant="contained" startIcon={<CarrierIcon />} endIcon={<CarrierIcon />} >Carrier</Button>
                        }

                    /><ListItemText

                        primary={
                            <Typography align="center" variant="h5" color="primary" >
                                {props.carrier}
                            </Typography>
                        }

                    />
                    <ListItemText style={{

                        color: "white",
                        textAlign: "center",

                    }}

                        primary={
                            <Button color="primary" variant="contained" fullWidth startIcon={<TextMap />} endIcon={<TextMap />} >Text Map</Button>
                        }

                    /><ListItemText

                        primary={
                            <Typography align="center" variant="h6" color="primary" >
                                {props.textMap}
                            </Typography>
                        }


                    />

                    <ListItemText style={{

                        color: "white",
                        textAlign: "center",

                    }}

                        primary={
                            <Button color="primary" variant="contained" fullWidth startIcon={<DomainMap />} endIcon={<DomainMap />} >Domain Map</Button>
                        }

                    />


                    {/* <div style={{height:"40px", overflow:"auto"}}> */}
                    {
                        props.domainMap.map((val, ind) => {

                            return (
                                <div key={val}>
                                    <Divider variant="fullWidth" />
                                    <MenuItem key={ind} style={{ backgroundColor: "#5d8cae", color: "#fff" }}><Typography align="right" variant="body1"  >
                                        <MapValueTextIcon />{val}
                                    </Typography></MenuItem>
                                </div>
                            )



                        })


                    }
{/* </div> */}
                </MenuList>

                <ListItemText style={{

                    color: "white",
                    textAlign: "center",

                }}

                    onClick={() => delteItem(props.carrierMapId)}
                    primary={
                        <Button color="secondary" fullWidth variant="contained"
                            startIcon={<DeleteIcon />}

                        >Delete This Map</Button>
                    }

                />

            </Paper>
        </Grid>
    )
}

export default CarrierMapDatas
