import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ToolTip from "./ToolTip"
import DeleteIcon from '@material-ui/icons/CancelPresentationTwoTone';
import AddIcon from '@material-ui/icons/AddBoxTwoTone';
import Alert from '@material-ui/lab/Alert';
import axios from "axios"
import { useHistory } from "react-router-dom"


const CampaignsFormDialog = (props) => {
    const history = useHistory()
    const [open, setOpen] = useState(false);
    const [addError, setAddError] = useState(false)
    const [campaign, setCampaign] = useState({ 
        campaign : "",
        dlrEndPoint : "",
        moEndPoint : "",
        backWashEndPoint : ""
    })
    const [errorText, setErrorText] = useState("")
    // const history = useHistory()

    const handleClickOpen = () => {
        setOpen(true);
        setErrorText("")
        setAddError(false)
        setCampaign({ 
            campaign : "",
            dlrEndPoint : "",
            moEndPoint : "",
            backWashEndPoint : ""
        })
    };

    const handleClose = () => {
        setOpen(false);
        setErrorText("")
        setAddError(false)
        setCampaign({ 
            campaign : "",
            dlrEndPoint : "",
            moEndPoint : "",
            backWashEndPoint : ""
        })
    };

    const submitHandleClose = async () => {
        const headers = {
            withCredentials: true
          }
          
          try {
      
            await axios.post(process.env.REACT_APP_API_URL + `/campaign/add`, campaign, headers )
            // console.log(getDataResult)
            setAddError(false)
            setOpen(false);
            props.handleUpdate(true)
            props.handleAddDelStatus("info", "Successfully Added !")
          }catch (error) {
    
            if (error.response.status === 401) {
              return (history.push("/"))
            }
            else if (error.response.status === 409) {
              setErrorText(`Conflict - On Processing : \n Campaign : ${error.response.data.campaign} - Already Exist !!`)
            }
            else{
            setErrorText("Error Occured - Unable To Add Campaign !")
          }
  
            setAddError(true)
          }
    }

    return (
        <div>
            <ToolTip openForm={() => handleClickOpen} />

            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adding New Campaign</DialogTitle>
                <div style={{ whiteSpace: "pre-wrap" }}>{addError && <Alert severity="error">{errorText}</Alert>}</div>
                <form method="post" onSubmit={(e) => { e.preventDefault(); submitHandleClose() }}>
                    <DialogContent>
                        <DialogContentText>
                            Notes :
                            <li>Campaign Is Required</li>
                            <li>DLR Endpoint Is Required</li>


                        </DialogContentText>

                        <TextField
                            autoComplete="off"
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            autoFocus
                            required
                            margin="dense"
                            id="campaign"
                            label="Campaign"
                            type="text"
                            onChange={(event) => setCampaign({ ...campaign, campaign: event.target.value.toUpperCase() })}
                            value={campaign.campaign}
                            fullWidth
                        />

                        <TextField
                            autoComplete="off"
                            // inputProps={{ style: { textTransform: "lowercase" } }}
                            required
                            margin="dense"
                            id="dlrEndPoint"
                            label="DLR Endpoint"
                            type="url"
                            onChange={(event) => setCampaign({ ...campaign, dlrEndPoint: event.target.value })}
                            value={campaign.dlrEndPoint}
                            fullWidth
                        />

                        <TextField
                            autoComplete="off"
                            // inputProps={{ style: { textTransform: "lowercase" } }}
                            margin="dense"
                            id="moEndPoint"
                            label="MO Endpoint"
                            type="url"
                            onChange={(event) => setCampaign({ ...campaign, moEndPoint: event.target.value })}
                            value={campaign.moEndPoint}
                            fullWidth
                        />

                        <TextField
                            autoComplete="off"
                            // inputProps={{ style: { textTransform: "lowercase" } }}
                            margin="dense"
                            id="backWashEndPoint"
                            label="BackWash Endpoint"
                            type="url"
                            onChange={(event) => setCampaign({ ...campaign, backWashEndPoint: event.target.value })}
                            value={campaign.backWashEndPoint}
                            fullWidth
                        />




                        {/* <TextField
              select
              inputProps={{style: {textTransform: "uppercase"}}}
              required
              margin="dense"
              id="simbox_port"
              label="Simbox Port"
              type="number"
              SelectProps={{
                native: true,
            }}
              fullWidth
              value={simBox}
              onChange={(event) => setSimBox(event.target.value)}
            >
            <option></option>
            <option select="8012">8012</option>
            <option select="8013">8013</option>
            <option select="8014">8014</option>
            <option select="8015">8015</option>
            <option select="8016">8016</option>
            
            </TextField> */}


                    </DialogContent>

                    <DialogActions>
                        <Button startIcon={<DeleteIcon />} onClick={handleClose} variant="contained" color="secondary">
                            Discard
          </Button>
                        <Button startIcon={<AddIcon />} type="submit" variant="contained" color="primary">
                            Save
          </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default CampaignsFormDialog
