import React from 'react'
import {ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import {NavLink} from "react-router-dom"


const SideBarList = (props) => {
    

    return (
                <ListItem  divider button component={NavLink} to={props.menu.to}>
                    <ListItemIcon >
                    <ListItemText style={{color:"#FFF"}}>
                        {props.menu.text}
                    </ListItemText>
                    </ListItemIcon>
                </ListItem>
        
    )
}

export default SideBarList
